import React from 'react';
import copyToClipboard from 'copy-to-clipboard';

const projects = {
  'GraphQL Code Generator': {
    link: 'http://bit.ly/connected-codegen',
  },
  'GraphQL Inspector': {
    link: 'http://bit.ly/connected-inspector',
  },
  'GraphQL Config': {
    link: 'http://bit.ly/connected-config',
  },
  'GraphQL Toolkit': {
    link: 'http://bit.ly/connected-toolkit',
  },
  'GraphQL Modules': {
    link: 'http://bit.ly/connected-modules',
  },
  'Apollo Angular': {
    link: 'http://bit.ly/connected-apollo-angular',
  },
  Sofa: {
    link: 'http://bit.ly/connected-sofa',
  },
};

const generateTemplate = project => {
  const link = projects[project].link;
  const template = `
> Do you want The Guild to keep your codebase up to date and run your build on each GraphQL Inspector commit so we will make sure not to break your app?
> Contact us here: [the-guild.dev/connected-build](${link})
> Chat with us [on discord](http://bit.ly/guild-chat)

List of changes:

...

`;

  copyToClipboard(template, {
    debug: true,
  });

  return template;
};

function App() {
  const projectNames = React.useMemo(() => Object.keys(projects), []);
  const [template, setTemplate] = React.useState();
  const [project, setProject] = React.useState('default');
  const pickProject = React.useCallback(
    event => setProject(event.target.value),
    [setProject],
  );

  React.useEffect(() => {
    if (!project || project === 'default') {
      setTemplate();
    } else {
      setTemplate(generateTemplate(project));
    }
  }, [setTemplate, project]);

  return (
    <div>
      <div>
        <select value={project} onChange={pickProject}>
          <option value='default'>Pick project...</option>
          {projectNames.map(projectName => (
            <option key={projectName} value={projectName}>
              {projectName}
            </option>
          ))}
        </select>
      </div>
      {template && (
        <div>
          <h2>copied to clipboard:</h2>
          <code>
            <pre>{template}</pre>
          </code>
        </div>
      )}
    </div>
  );
}

export default App;
